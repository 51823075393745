exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-contentful-person-name-js": () => import("./../../../src/pages/{ContentfulPerson.name}.js" /* webpackChunkName: "component---src-pages-contentful-person-name-js" */),
  "component---src-pages-contentful-service-name-js": () => import("./../../../src/pages/{ContentfulService.name}.js" /* webpackChunkName: "component---src-pages-contentful-service-name-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-contentful-service-url-js": () => import("./../../../src/pages/people/{ContentfulService.url}.js" /* webpackChunkName: "component---src-pages-people-contentful-service-url-js" */),
  "component---src-pages-people-index-js": () => import("./../../../src/pages/people/index.js" /* webpackChunkName: "component---src-pages-people-index-js" */)
}

